import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import Toast from 'components/Toast/Toast';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import { useQueryClient } from '@tanstack/react-query';
import _t from 'counterpart';
const PriceAlertToasts = () => {
    const priceAlerts = useAppSelector((state) => state.priceAlerts);
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const handleToastOpenChange = (open, index) => {
        if (!open) {
            dispatch(actions.removePriceAlertAtIndex(index));
        }
    };
    useEffect(() => {
        queryClient.invalidateQueries(['priceAlerts']);
    }, [priceAlerts, queryClient]);
    return (_jsx(_Fragment, { children: priceAlerts.map((priceAlert, index) => {
            const type = priceAlert.trigger.includes('below') ? _t('main.below') : _t('main.above');
            return (_jsx(Toast, { defaultOpen: true, onOpenChange: (open) => handleToastOpenChange(open, index), title: _t('main.price_alert'), description: _t('main.price_alert_description', {
                    symbol: priceAlert.symbol,
                    price: priceAlert.price,
                    type,
                }), variant: 'warning' }, priceAlert.id));
        }) }));
};
export default React.memo(PriceAlertToasts);
