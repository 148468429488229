import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * Intended usage is navigating back to parent url
 * example: /trading/instruments/USDHKD -> /trading/instruments
 * @param {string} parentUrl The URL to which the user will be navigated back.
 */
export const useGoBackTo = (parentUrl) => {
    const history = useHistory();
    return useCallback(() => {
        history.replace(parentUrl);
    }, [parentUrl, history]);
};
