import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import config from 'config';
import { PageLayout } from '../global/PageLayout';
import { useHistory } from 'react-router-dom';
import _t from 'counterpart';
import IframeWrapper from 'components/IframeWrapper/IframeWrapper';
import { useAppSelector } from 'wintrado-api';
const PageDeposit = () => {
    const user = useAppSelector((state) => state.user);
    const { depositURL } = config.cashier;
    const history = useHistory();
    const handleReceivedMessage = useCallback((e) => {
        if (e.data && ['WT_DEPOSIT_SUCCESS', 'WT_DEPOSIT_FAILURE'].includes(e.data)) {
            history.push('/');
        }
    }, [history]);
    useEffect(() => {
        window.addEventListener('message', handleReceivedMessage);
        return () => {
            window.removeEventListener('message', handleReceivedMessage);
        };
    });
    if (!depositURL || !user || !user.wallet) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(PageLayout, Object.assign({ className: "deposit", isShowingEmbeddedIframe: true }, { children: _jsx(IframeWrapper, { url: depositURL ? depositURL(user, _t.getLocale()) : '' }) })));
};
export default React.memo(PageDeposit);
