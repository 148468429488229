import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import _t from 'counterpart';
import TradingSidebarLayout from 'components/TradingSidebarLayout/TradingSidebarLayout';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import { useParams } from 'react-router-dom';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
import EditPositionForm from 'components/EditPositionForm/EditPositionForm';
import { useAppSelector, useInstrument } from 'wintrado-api';
const EditPositionSidebar = () => {
    const { positionId } = useParams();
    const positions = useAppSelector((state) => state.positions);
    const positionToEdit = Object.values(positions).find((position) => position.positionId === Number(positionId));
    const { instrument } = useInstrument(positionToEdit === null || positionToEdit === void 0 ? void 0 : positionToEdit.symbol);
    const goBack = useGoBackTo('/trading');
    useEffect(() => {
        if (positionToEdit !== undefined && positionToEdit.status !== 'open') {
            goBack();
        }
    }, [goBack, positionId, positionToEdit]);
    return (_jsx(TradingSidebarLayout, Object.assign({ title: _t('main.modify_position', { orderId: `#${positionId}` }), onBackClick: goBack }, { children: isSet(positionToEdit) && instrument !== null ? (_jsx(EditPositionForm, { onGoBack: goBack, position: positionToEdit, instrument: instrument })) : (_jsx(Alert, { variant: 'error', title: _t('error.oops'), description: _t('error.position_not_found') })) })));
};
export default React.memo(EditPositionSidebar);
