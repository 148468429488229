import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import _t from 'counterpart';
import TradingSidebarLayout from 'components/TradingSidebarLayout/TradingSidebarLayout';
import PlaceOrderForm from 'components/PlaceOrderForm/PlaceOrderForm';
import { useGoBackTo } from '../../hooks/useGoBackTo';
const PlaceOrderSidebar = ({ onGoBack, backIconType }) => {
    const goBack = useGoBackTo('/trading');
    const handleGoBack = useCallback(() => {
        if (onGoBack !== undefined) {
            onGoBack();
        }
        else {
            goBack();
        }
    }, [onGoBack, goBack]);
    return (_jsx(TradingSidebarLayout, Object.assign({ title: _t('main.place_order'), onBackClick: handleGoBack, backIconType: backIconType }, { children: _jsx(PlaceOrderForm, { onGoBack: handleGoBack }) })));
};
export default React.memo(PlaceOrderSidebar);
