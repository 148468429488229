import { jsx as _jsx } from "react/jsx-runtime";
import _t from 'counterpart';
import config from 'config';
import Button from '../Button/Button';
import AnyLink from 'components/AnyLink/AnyLink';
export const OpenAccountButton = ({ fullWidth = false }) => {
    var _a, _b;
    const registrationUrl = (_b = (_a = config.cashier).registrationLink) === null || _b === void 0 ? void 0 : _b.call(_a, '', _t.getLocale());
    if (!registrationUrl) {
        return null;
    }
    return (_jsx(AnyLink, Object.assign({ to: registrationUrl, className: "open-account-button" }, { children: _jsx(Button, Object.assign({ blue: true, fullWidth: fullWidth }, { children: _t.translate('login.create_account') })) })));
};
