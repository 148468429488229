var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const saveLoadAdapter = {
    getAllCharts: function () {
        const charts = JSON.parse(localStorage.getItem('charts') || '[]');
        return Promise.resolve(charts);
    },
    removeChart: function (id) {
        const charts = JSON.parse(localStorage.getItem('charts') || '[]');
        const chartIndex = charts.findIndex((chart) => (chart === null || chart === void 0 ? void 0 : chart.id) === id);
        if (chartIndex > -1) {
            charts.splice(chartIndex, 1);
            localStorage.setItem('charts', JSON.stringify(charts));
            return Promise.resolve();
        }
        return Promise.reject();
    },
    saveChart: function (chartData) {
        const charts = JSON.parse(localStorage.getItem('charts') || '[]');
        if (!chartData.id) {
            // @ts-ignore
            chartData.id = Math.random().toString();
        }
        else {
            const existingIndex = charts.findIndex((chart) => chart.id === chartData.id);
            if (existingIndex > -1) {
                charts.splice(existingIndex, 1);
            }
        }
        charts.push(chartData);
        localStorage.setItem('charts', JSON.stringify(charts));
        return Promise.resolve(chartData.id);
    },
    getChartContent: function (id) {
        const charts = JSON.parse(localStorage.getItem('charts') || '[]');
        const chart = charts.find((chart) => chart.id === id.toString());
        if (chart) {
            return Promise.resolve(chart.content);
        }
        console.error('Chart not found');
        return Promise.reject();
    },
    removeStudyTemplate: function (studyTemplateData) {
        const studyTemplates = JSON.parse(localStorage.getItem('studyTemplates') || '[]');
        const index = studyTemplates.findIndex((template) => template.name === studyTemplateData.name);
        if (index > -1) {
            studyTemplates.splice(index, 1);
            localStorage.setItem('studyTemplates', JSON.stringify(studyTemplates));
            return Promise.resolve();
        }
        return Promise.reject();
    },
    saveStudyTemplate: function (studyTemplateData) {
        const studyTemplates = JSON.parse(localStorage.getItem('studyTemplates') || '[]');
        const existingIndex = studyTemplates.findIndex((template) => template.name === studyTemplateData.name);
        if (existingIndex > -1) {
            studyTemplates.splice(existingIndex, 1);
        }
        studyTemplates.push(studyTemplateData);
        localStorage.setItem('studyTemplates', JSON.stringify(studyTemplates));
        return Promise.resolve();
    },
    getAllStudyTemplates: function () {
        const studyTemplates = JSON.parse(localStorage.getItem('studyTemplates') || '[]');
        return Promise.resolve(studyTemplates);
    },
    getStudyTemplateContent: function (studyTemplateData) {
        const studyTemplates = JSON.parse(localStorage.getItem('studyTemplates') || '[]');
        const template = studyTemplates.find((t) => t.name === studyTemplateData.name);
        if (template) {
            return Promise.resolve(template.content);
        }
        else {
            console.error('Study template error');
            return Promise.reject();
        }
    },
    removeDrawingTemplate: function (toolName, templateName) {
        const drawingTemplates = JSON.parse(localStorage.getItem('drawingTemplates') || '[]');
        const index = drawingTemplates.findIndex((t) => t.name === templateName);
        if (index > -1) {
            drawingTemplates.splice(index, 1);
            localStorage.setItem('drawingTemplates', JSON.stringify(drawingTemplates));
            return Promise.resolve();
        }
        else {
            return Promise.reject();
        }
    },
    loadDrawingTemplate: function (toolName, templateName) {
        const drawingTemplates = JSON.parse(localStorage.getItem('drawingTemplates') || '[]');
        const template = drawingTemplates.find((t) => t.name === templateName);
        if (template) {
            return Promise.resolve(template.content);
        }
        else {
            console.error('Drawing template error');
            return Promise.reject();
        }
    },
    saveDrawingTemplate: function (toolName, templateName, content) {
        const drawingTemplates = JSON.parse(localStorage.getItem('drawingTemplates') || '[]');
        const existingIndex = drawingTemplates.findIndex((t) => t.name === templateName);
        if (existingIndex > -1) {
            drawingTemplates.splice(existingIndex, 1);
        }
        drawingTemplates.push({ name: templateName, content });
        localStorage.setItem('drawingTemplates', JSON.stringify(drawingTemplates));
        return Promise.resolve();
    },
    getDrawingTemplates: function () {
        const drawingTemplates = JSON.parse(localStorage.getItem('drawingTemplates') || '[]');
        return Promise.resolve(drawingTemplates.map((template) => template.name));
    },
    getAllChartTemplates: function () {
        return __awaiter(this, void 0, void 0, function* () {
            const chartTemplates = JSON.parse(localStorage.getItem('chartTemplates') || '[]');
            return chartTemplates.map((x) => x.name);
        });
    },
    saveChartTemplate: function (templateName, content) {
        return __awaiter(this, void 0, void 0, function* () {
            const chartTemplates = JSON.parse(localStorage.getItem('chartTemplates') || '[]');
            const index = chartTemplates.findIndex((x) => x.name === templateName);
            if (index > -1) {
                chartTemplates[index].content = content;
            }
            else {
                chartTemplates.push({ name: templateName, content });
            }
            localStorage.setItem('chartTemplates', JSON.stringify(chartTemplates));
        });
    },
    removeChartTemplate: function (templateName) {
        return __awaiter(this, void 0, void 0, function* () {
            let chartTemplates = JSON.parse(localStorage.getItem('chartTemplates') || '[]');
            chartTemplates = chartTemplates.filter((x) => x.name !== templateName);
            localStorage.setItem('chartTemplates', JSON.stringify(chartTemplates));
        });
    },
    getChartTemplateContent: function (templateName) {
        return __awaiter(this, void 0, void 0, function* () {
            const chartTemplates = JSON.parse(localStorage.getItem('chartTemplates') || '[]');
            const template = chartTemplates.find((x) => x.name === templateName);
            if (template) {
                return {
                    content: structuredClone(template.content),
                };
            }
            else {
                return Promise.reject('Chart template not found');
            }
        });
    },
};
