import { jsx as _jsx } from "react/jsx-runtime";
import _t from "counterpart";
import styles from "./ClosedPositionToastDescription.module.scss";
import { formatCurrency } from "utils/currency";
const ClosedPositionToastDescription = ({ closePrice, instrumentDecimals, profitLoss, accountCurrency }) => {
    return (_jsx("div", { dangerouslySetInnerHTML: {
            __html: _t('toast.position_closed_description', {
                closePrice: closePrice.toFixed(instrumentDecimals),
                profitLoss: `<span class="${styles.profitLoss} ${profitLoss >= 0 ? styles.positive : styles.negative}">${profitLoss >= 0 ? '+' : ''}${formatCurrency(profitLoss, accountCurrency)}</span>`,
            }),
        } }));
};
export default ClosedPositionToastDescription;
