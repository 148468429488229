import { useState, useCallback, useEffect } from 'react';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
const useCrosshairPriceSelection = (supportedFields, setValueCallback, decimals) => {
    const crosshairPriceSelection = useAppSelector((state) => state.proTradingScreen.crosshairPriceSelection);
    const dispatch = useAppDispatch();
    const [activeFieldId, setActiveFieldId] = useState(null);
    const [livePricesPerField, setLivePricesPerField] = useState({});
    useEffect(() => {
        var _a;
        if (activeFieldId !== null) {
            setLivePricesPerField({
                [activeFieldId]: (_a = crosshairPriceSelection.livePrice) === null || _a === void 0 ? void 0 : _a.toFixed(decimals),
            });
        }
    }, [crosshairPriceSelection.livePrice, activeFieldId, decimals]);
    // handles setting the selected value from the crosshair selection to the correct field
    useEffect(() => {
        if (activeFieldId !== null && crosshairPriceSelection.selectedPrice !== null) {
            setValueCallback(activeFieldId, crosshairPriceSelection.selectedPrice);
            dispatch(actions.disableCrosshairPriceSelection());
        }
    }, [activeFieldId, crosshairPriceSelection.selectedPrice, dispatch, setValueCallback]);
    //handles clearing the crosshairPriceActiveOnField when the crosshairPriceSelection is disabled
    useEffect(() => {
        if (!crosshairPriceSelection.enabled) {
            setActiveFieldId(null);
        }
    }, [crosshairPriceSelection.enabled]);
    const disablePriceSelectionIfActive = useCallback(() => {
        if (activeFieldId !== null) {
            dispatch(actions.disableCrosshairPriceSelection());
        }
    }, [activeFieldId, dispatch]);
    const handleCrosshairClickedForField = useCallback((fieldId) => {
        if (crosshairPriceSelection.enabled) {
            if (activeFieldId === fieldId) {
                setActiveFieldId(null);
                dispatch(actions.disableCrosshairPriceSelection());
            }
            else {
                setActiveFieldId(fieldId);
                setValueCallback(fieldId, null);
            }
        }
        else {
            setValueCallback(fieldId, null);
            setActiveFieldId(fieldId);
            dispatch(actions.enableCrosshairPriceSelection());
        }
    }, [activeFieldId, crosshairPriceSelection.enabled, dispatch, setValueCallback]);
    return {
        livePricesPerField,
        activeFieldId,
        disablePriceSelectionIfActive,
        handleCrosshairClickedForField,
    };
};
export default useCrosshairPriceSelection;
