import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { persistor, store } from 'wintrado-api';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { isAndroid, isIos } from '../../helpers';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import usePageTracking from '../../hooks/usePageTracking';
import useScript from '../../hooks/useScript';
import config from 'config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Main from '../../Main';
import { IconContext } from 'react-icons';
import styles from './App.module.scss';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import InstallCTA from "../../old/components/main/InstallCTA";
const queryClient = new QueryClient();
const App = () => {
    usePageTracking();
    useEffect(() => {
        const { body } = document;
        if (isIos()) {
            body.classList.add('ios');
        }
        if (isAndroid()) {
            body.classList.add('android');
        }
        Modal.setAppElement('#app');
    }, []);
    if (config.customScriptInject) {
        useScript(config.customScriptInject);
    }
    return (_jsx(TooltipProvider, Object.assign({ delayDuration: 400 }, { children: _jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsx(PersistGate, Object.assign({ loading: null, persistor: persistor }, { children: _jsxs(IconContext.Provider, Object.assign({ value: { size: '24', className: styles.icons } }, { children: [_jsx(InstallCTA, {}), _jsx(Main, {})] })) })) })) })) })));
};
export default App;
