import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import styles from './PositionsListItem.module.scss';
import _t from 'counterpart';
import { formatDateTime, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import { formatCurrency } from 'utils/currency';
import classNames from 'classnames';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useHistory, useRouteMatch } from 'react-router-dom';
const PositionListItem = ({ position, isNew, instrument, currentPrice }) => {
    const match = useRouteMatch('/trading/positions/:positionId');
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const editingPositionId = match !== null ? Number(match.params.positionId) : null;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const timeoutRef = useRef(null);
    const [isNewLocked, setIsNewLocked] = useState(false);
    const handleRowClick = (e, position) => {
        e.preventDefault();
        if (!position.positionId) {
            throw Error('Trying to click position without positionId');
        }
        const url = `/trading/positions/${position.positionId}`;
        dispatch(actions.setActivePositionId(position.positionId));
        if (history.location.pathname.includes('/trading/positions/')) {
            history.replace(url);
        }
        else {
            history.push(url);
        }
    };
    useEffect(() => {
        if (isNew) {
            setIsNewLocked(true);
        }
    }, [isNew]);
    useEffect(() => {
        if (isNewLocked) {
            timeoutRef.current = setTimeout(() => {
                setIsNewLocked(false);
            }, 4000);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isNewLocked]);
    const resetIsNew = () => {
        if (isSet(timeoutRef === null || timeoutRef === void 0 ? void 0 : timeoutRef.current)) {
            clearTimeout(timeoutRef.current);
            setIsNewLocked(false);
        }
    };
    const formatStopLoss = () => {
        if (isSet(position.stopLoss)) {
            return position.stopLoss;
        }
        if (isSet(position.trailingStopLossPct)) {
            return Intl.NumberFormat(_t.getLocale(), { style: 'percent', minimumFractionDigits: 0 }).format(position.trailingStopLossPct / 100);
        }
        return '—';
    };
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    _jsxs("div", Object.assign({ className: classNames(styles.position, {
            [styles.positionActive]: editingPositionId === position.positionId,
            [styles.positionNewPosition]: isNewLocked,
        }), onClick: (e) => handleRowClick(e, position), onMouseOver: resetIsNew, onFocus: resetIsNew }, { children: [_jsxs("div", { children: ["#", position.positionId] }), _jsx(InstrumentIconName, { instrument: instrument }), _jsx("div", { children: isSet(position.openedAt) && formatDateTime(position.openedAt) }), _jsx("div", { children: isSet(position.side) ? _t(`main.${position.side.toLowerCase()}`) : _jsx(LoadingDots, {}) }), _jsx("div", Object.assign({ className: styles.size }, { children: isSet(position.size) ? position.size : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: styles.openPrice }, { children: isSet(position.openPrice) ? position.openPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: styles.stopLoss }, { children: formatStopLoss() })), _jsx("div", Object.assign({ className: styles.takeProfit }, { children: isSet(position.takeProfit) ? position.takeProfit : '—' })), _jsx("div", Object.assign({ className: styles.currentPrice }, { children: isSet(currentPrice) ? currentPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: classNames(styles.profit, {
                    [styles.profitNegative]: isSet(position.profitLoss) && position.profitLoss < 0,
                    [styles.profitPositive]: isSet(position.profitLoss) && position.profitLoss >= 0,
                }) }, { children: isSet(position.profitLoss) ? formatCurrency(position.profitLoss, accountCurrency) : _jsx(LoadingDots, {}) }))] })));
};
export default PositionListItem;
