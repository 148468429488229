var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import Button from '../Button/Button';
import Loader from 'components/Loader/Loader';
import axios from 'axios';
import styles from './exportXLSXButton.module.scss';
import exportIcon from '../../assets/icons/export.svg?url';
const _t = require('counterpart');
const ExportXLSXButton = ({ downloadLink, disabled = false, defaultFilename }) => {
    const [loading, setLoading] = useState(false);
    const handleDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const response = yield axios({
                url: downloadLink,
                method: 'GET',
                responseType: 'blob',
            });
            const href = URL.createObjectURL(response.data);
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition.split('filename=')[1];
            const link = document.createElement('a');
            link.href = href;
            link.download = defaultFilename;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (e) {
            Sentry.captureException(e);
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsx(Button, Object.assign({ className: styles.button, variant: "primary", flexible: true, onClick: handleDownload, disabled: disabled || loading }, { children: loading ? (_jsx(Loader, { inline: true })) : (_jsxs(_Fragment, { children: [_jsx("img", { src: exportIcon, alt: "export" }), _t('main.export-xlsx')] })) })));
};
export default ExportXLSXButton;
