import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './BalanceBar.module.scss';
import { selectors, useAppSelector } from 'wintrado-api';
import _t from 'counterpart';
import { formatCurrency } from 'utils/currency';
import { decimalToPercentageString, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import classNames from 'classnames';
const BalanceBar = () => {
    const usedMargin = useAppSelector(selectors.usedMarginSelector);
    const marginLevel = useAppSelector(selectors.marginLevelSelector);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const totalPL = useAppSelector(selectors.totalProfitLossSelector);
    const equity = useAppSelector(selectors.equitySelector);
    const freeMargin = useAppSelector(selectors.freeMarginSelector);
    const marginCall = useAppSelector(selectors.marginCallSelector);
    const formattedMarginLevel = useMemo(() => {
        if (!isSet(marginLevel)) {
            return null;
        }
        return marginLevel === 0 ? 'N/A' : decimalToPercentageString(marginLevel);
    }, [marginLevel]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: `${_t('main.equity')}: ` })), _jsx("span", Object.assign({ className: styles.value }, { children: isSet(equity) ? formatCurrency(equity, accountCurrency) : _jsx(LoadingDots, {}) }))] })), _jsxs("div", Object.assign({ className: classNames(styles.item, styles.itemMargin) }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: `${_t('main.margin')}: ` })), _jsx("span", Object.assign({ className: styles.value }, { children: isSet(usedMargin) ? formatCurrency(usedMargin, accountCurrency) : _jsx(LoadingDots, {}) }))] })), _jsxs("div", Object.assign({ className: classNames(styles.item, {
                    [styles.itemFlashing]: marginCall,
                }) }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: `${_t('main.free_margin')}: ` })), _jsx("span", Object.assign({ className: styles.value }, { children: isSet(freeMargin) ? formatCurrency(freeMargin, accountCurrency) : _jsx(LoadingDots, {}) }))] })), _jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: `${_t('main.margin_level')}: ` })), _jsx("span", Object.assign({ className: styles.value }, { children: isSet(formattedMarginLevel) ? formattedMarginLevel : _jsx(LoadingDots, {}) }))] })), _jsxs("div", Object.assign({ className: classNames(styles.item, {
                    [styles.itemPositive]: (totalPL !== null && totalPL !== void 0 ? totalPL : 0) >= 0,
                    [styles.itemNegative]: (totalPL !== null && totalPL !== void 0 ? totalPL : 0) < 0,
                }) }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: `${_t('main.profit_loss')}: ` })), _jsx("span", Object.assign({ className: styles.value }, { children: formatCurrency(totalPL !== null && totalPL !== void 0 ? totalPL : 0, accountCurrency) }))] }))] })));
};
export default React.memo(BalanceBar);
