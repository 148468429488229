import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styles from './MobileSheetLayout.module.scss';
import classNames from 'classnames';
import TitleHeader from 'components/TitleHeader/TitleHeader';
import Sheet from 'react-modal-sheet';
import { mobileHeaderHeight } from '../../cssVariables';
const MobileSheetLayout = ({ children, title, onClosed, endElements, className, padded, detent = 'content-height', }) => {
    const [isSheetOpen, setSheetOpen] = React.useState(false);
    const onCloseSheet = () => {
        setSheetOpen(false);
    };
    useEffect(() => {
        setSheetOpen(true);
    }, []);
    return (_jsx(Sheet, Object.assign({ isOpen: isSheetOpen, onClose: onCloseSheet, onCloseEnd: onClosed, detent: detent, snapPoints: [-mobileHeaderHeight], disableScrollLocking: true }, { children: _jsxs(Sheet.Container, Object.assign({ className: classNames(styles.container, className, {
                [styles.containerPadded]: padded,
            }) }, { children: [_jsx(Sheet.Header, {}), title !== undefined && _jsx(TitleHeader, { title: title, onBackClick: onCloseSheet, endElements: endElements }), _jsx(Sheet.Content, Object.assign({ className: styles.sheetContent }, { children: _jsx(Sheet.Scroller, Object.assign({ className: styles.scroller }, { children: _jsx("div", Object.assign({ className: styles.contentWrapper }, { children: children })) })) }))] })) })));
};
export default React.memo(MobileSheetLayout);
