import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styles from './InstrumentsList.module.scss';
import _t from 'counterpart';
import { formatSpread, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { actions, selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import IconButton from 'components/IconButton/IconButton';
import classNames from 'classnames';
import TextField from 'components/TextField/TextField';
import { FiInfo, FiSearch } from 'react-icons/fi';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
const InstrumentsList = ({ instruments, showSearch, onInstrumentClick, emptyPlaceholder }) => {
    const [searchValue, setSearchValue] = React.useState('');
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const dispatch = useAppDispatch();
    const handleRowClick = useCallback((e, instrument) => {
        e.preventDefault();
        dispatch(actions.setActiveInstrument(instrument.symbol));
        onInstrumentClick === null || onInstrumentClick === void 0 ? void 0 : onInstrumentClick(instrument.symbol);
    }, [dispatch, onInstrumentClick]);
    const handleSearchValueChanged = useCallback((e) => {
        setSearchValue(e.target.value);
    }, []);
    const filteredInstruments = useMemo(() => {
        if (searchValue.trim().length === 0) {
            return instruments;
        }
        return instruments.filter((instrument) => {
            return instrument.symbol.toLowerCase().includes(searchValue.toLowerCase());
        });
    }, [instruments, searchValue]);
    const instrumentsPerCategory = useMemo(() => {
        return filteredInstruments.reduce((acc, instrument) => {
            const category = instrument.category;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(instrument);
            return acc;
        }, {});
    }, [filteredInstruments]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [showSearch && (_jsx(TextField, { iconStart: _jsx(FiSearch, {}), className: styles.searchBox, label: _t('main.search'), value: searchValue, onChange: handleSearchValueChanged })), _jsxs("div", Object.assign({ className: styles.table }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("span", { children: _t('instrument.symbol') }), _jsx("span", Object.assign({ className: styles.askBidLabel }, { children: _t('main.ask/bid') })), _jsx("span", Object.assign({ className: styles.spreadLabel }, { children: _t('instrument.spread') })), _jsx("span", {})] })), filteredInstruments.length === 0 && emptyPlaceholder, Object.entries(instrumentsPerCategory).map(([category, instruments]) => (_jsxs(React.Fragment, { children: [_jsx("h6", Object.assign({ className: styles.categoryLabel }, { children: category })), instruments.map((instrument) => {
                                const instrumentQuote = latestQuotes[instrument.symbol];
                                return (_jsxs("div", Object.assign({ className: classNames(styles.instrument, {
                                        [styles.instrumentActive]: (activeInstrument === null || activeInstrument === void 0 ? void 0 : activeInstrument.symbol) === instrument.symbol,
                                    }), onClick: (e) => handleRowClick(e, instrument) }, { children: [_jsx(InstrumentIconName, { instrument: instrument }), _jsxs("div", Object.assign({ className: styles.askBid }, { children: [_jsx("span", Object.assign({ className: styles.ask }, { children: isSet(instrumentQuote) ? instrumentQuote.ask.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("span", Object.assign({ className: styles.bid }, { children: isSet(instrumentQuote) ? instrumentQuote.bid.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) }))] })), _jsx("div", Object.assign({ className: styles.spread }, { children: isSet(instrumentQuote) ? (_jsx("span", { children: formatSpread(instrumentQuote.spread, instrument.decimals) })) : (_jsx(LoadingDots, {})) })), _jsx("div", Object.assign({ className: styles.alertWrapper }, { children: _jsx(IconButton, Object.assign({ to: `/trading/instruments/${instrument.symbol}`, size: 'small', onClick: (e) => e.stopPropagation() }, { children: _jsx(FiInfo, {}) })) }))] }), instrument.symbol));
                            })] }, category)))] }))] })));
};
export default React.memo(InstrumentsList);
