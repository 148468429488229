import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageLayout } from '../../global/PageLayout';
import _t from 'counterpart';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styles from './LoyaltyProfile.module.scss';
import LoyaltyStatus from '../../LoyaltyStatus/LoyaltyStatus';
import LoyaltyDetails from '../../LoyaltyDetails/LoyaltyDetails';
import LoyaltyBenefits from '../../LoyaltyBenefits/LoyaltyBenefits';
import { useAppSelector } from 'wintrado-api';
const LoyaltyProfile = () => {
    const loyaltyLevel = useAppSelector((state) => state.user.loyaltyLevel);
    return (_jsx(PageLayout, Object.assign({ title: _t.translate('loyalty.title'), containerClassName: styles.container, wrapperClassName: styles.contentWrapper }, { children: _jsxs(Tabs, Object.assign({ className: styles.tabsWrapper }, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: _t.translate('loyalty.status') }), _jsx(Tab, { children: _t.translate('loyalty.benefits') }), _jsx(Tab, { children: _t.translate('loyalty.details') })] }), _jsx(TabPanel, { children: _jsx(LoyaltyStatus, { loyaltyLevel: loyaltyLevel }) }), _jsx(TabPanel, { children: _jsx(LoyaltyBenefits, {}) }), _jsx(TabPanel, { children: _jsx(LoyaltyDetails, {}) })] })) })));
};
export default LoyaltyProfile;
