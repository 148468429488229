import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from 'components/TextField/TextField';
import styles from './DateRangePicker.module.scss';
import dateIcon from '../../assets/icons/date.svg?url';
const DateRangePicker = ({ dateRange, onRangeChanged, label, error }) => {
    const pickerRef = useRef(null);
    const { startDate, endDate } = dateRange;
    const handleDateRangeUpdated = ([startDate, endDate]) => {
        endDate === null || endDate === void 0 ? void 0 : endDate.setHours(23, 59, 59, 999);
        onRangeChanged({ startDate, endDate });
    };
    return (_jsx(DatePicker, { ref: pickerRef, dateFormat: "dd/MM/yyyy", customInput: _jsx(TextField, { label: label, error: error, className: styles.textField, iconEnd: _jsx("img", { alt: "date-range", src: dateIcon }) }), selectsRange: true, startDate: startDate, endDate: endDate, onChange: (update) => {
            handleDateRangeUpdated(update);
        } }));
};
export default DateRangePicker;
