import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styles from './Header.module.scss';
import BalanceStat from 'components/BalanceStat/BalanceStat';
import CoinsStat from 'components/CoinsStat/CoinsStat';
import Spacer from 'components/Spacer/Spacer';
import IconButton from 'components/IconButton/IconButton';
import { FiMessageCircle, FiSettings } from 'react-icons/fi';
import { useIsMobile, useIsTablet } from 'utils/responsiveHelpers';
import MobileSidebar from 'components/MobileSidebar/MobileSidebar';
import AccountInfo from 'components/AccountInfo/AccountInfo';
import { supportsCoinsSelector } from 'wintrado-api/src/selectors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUnreadMessagesCount } from 'wintrado-api/src/services/crmService';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import config from 'config';
const INVALIDATE_MESSAGE_QUERY = 'INVALIDATE_MESSAGE_QUERY';
const MESSAGE_COUNT_DISPLAY_LIMIT = 99;
const Header = () => {
    var _a, _b;
    const { hasMessages } = config.cashier;
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();
    const supportsCoins = useAppSelector(supportsCoinsSelector);
    const unreadMessagesQuery = useQuery({
        queryKey: ['unread-messages'],
        queryFn: () => getUnreadMessagesCount(),
        refetchOnWindowFocus: true,
    });
    const unreadMessagesCount = (_b = (_a = unreadMessagesQuery.data) === null || _a === void 0 ? void 0 : _a.unreadCount) !== null && _b !== void 0 ? _b : 0;
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    useEffect(() => {
        const channel = new BroadcastChannel('sw-messages');
        const handleMessage = (event) => {
            const { type } = event.data;
            if (type !== undefined && type === INVALIDATE_MESSAGE_QUERY) {
                queryClient.invalidateQueries(['unread-messages']);
                dispatch(actions.fetchMessages());
            }
        };
        channel.addEventListener('message', handleMessage);
        return () => {
            channel.removeEventListener('message', handleMessage);
            channel.close();
        };
    }, [dispatch, queryClient]);
    const formatMessageCount = (value) => {
        if (value > MESSAGE_COUNT_DISPLAY_LIMIT) {
            return '99+';
        }
        return value;
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx(BalanceStat, {}), _jsx(Spacer, { x: "small" }), supportsCoins && (_jsxs(_Fragment, { children: [_jsx(CoinsStat, {}), _jsx(Spacer, { x: "small" })] }))] })), _jsx(MobileSidebar, { className: styles.mobileSidebar }), _jsx(AccountInfo, {}), _jsx(Spacer, { x: "small" }), hasMessages && (_jsx(IconButton, Object.assign({ to: "/messages", size: isTablet ? 'small' : 'normal', className: styles.messageIconButton }, { children: _jsxs(_Fragment, { children: [_jsx(FiMessageCircle, {}), unreadMessagesCount !== 0 && (_jsx("div", Object.assign({ className: styles.messagesCount }, { children: _jsx("span", { children: formatMessageCount(unreadMessagesCount) }) })))] }) }))), _jsx(Spacer, { x: "small" }), _jsx(IconButton, Object.assign({ to: "/settings", exact: true, size: isTablet ? 'small' : 'normal' }, { children: _jsx(FiSettings, {}) }))] })));
};
export default React.memo(Header);
