import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export function MessageItem({ id, content, date, unread, subject }) {
	return (
		<NavLink
			to={`/messages/${id}`}
			className={classNames('message-item', {
				'message-item--unread': unread,
			})}
		>
			<div className="message-item__header">
				<p className="message-item__title">{subject}</p>
				<div className="message-item__end">
					<span className="message-item__date">{date.toLocaleDateString()}</span>
					<div className="message-item__unread-badge" />
				</div>
			</div>
			<div className="message-item__content">
				<p>{content}</p>
			</div>
		</NavLink>
	);
}
