import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import Select from 'components/Select/Select';
import SelectItem from 'components/Select/SelectItem';
import ExportXLSXButton from 'components/ExportXLSXButton/ExportXLSXButton';
import DateRangerPicker from 'components/DateRangerPicker/DateRangePicker';
import { crmService, useAppSelector } from 'wintrado-api';
import styles from './StatementFilters.module.scss';
import { useQuery } from '@tanstack/react-query';
import { Mobile, TabletUp, useIsMobile } from 'utils/responsiveHelpers';
import Spacer from 'components/Spacer/Spacer';
const _t = require('counterpart');
const StatementFilters = ({ currentWallet, show, onWalletSelected, onOutsideClick, onRangeChanged, getExportUrl, dateRange, dataCount, }) => {
    var _a, _b;
    const [isWalletsOpen, setIsWalletsOpen] = useState(false);
    const filtersRef = useRef(null);
    const token = useAppSelector((state) => state.user.token);
    const start = (_a = dateRange.startDate) === null || _a === void 0 ? void 0 : _a.toISOString();
    const end = (_b = dateRange.endDate) === null || _b === void 0 ? void 0 : _b.toISOString();
    const hasRange = start !== undefined && end !== undefined;
    const userWalletsQuery = useQuery({
        queryKey: ['user-wallets'],
        queryFn: () => crmService.getUserWallets(),
    });
    const onValueChange = (value) => {
        onWalletSelected(value);
    };
    const isMobile = useIsMobile();
    const showBackground = isMobile && show;
    const showFilters = !isMobile || (isMobile && show);
    const outsideClickEvent = () => {
        if (!isWalletsOpen) {
            onOutsideClick();
        }
    };
    const handleWalletOpen = (open) => {
        setIsWalletsOpen(open);
    };
    return (_jsxs(_Fragment, { children: [showBackground && (_jsx("button", { className: `${styles.filterBackground} ${showBackground && styles.filterFadeIn}`, name: "filter-close-button", onClick: outsideClickEvent })), showFilters && (_jsxs("div", Object.assign({ className: `${styles.filter} ${isMobile && styles.filterSlideUp}`, ref: filtersRef }, { children: [_jsxs(Mobile, { children: [_jsx("div", { className: styles.line }), _jsx("span", { children: _t('main.filter') }), _jsx(Spacer, { y: "extraSmall" })] }), _jsx("div", Object.assign({ className: styles.inputField }, { children: _jsx(Select, Object.assign({ value: currentWallet, onValueChange: onValueChange, onOpenChange: handleWalletOpen }, { children: userWalletsQuery.data &&
                                userWalletsQuery.data.map((w) => {
                                    return (_jsx(SelectItem, Object.assign({ value: w.username }, { children: w.username }), w.username));
                                }) })) })), _jsx(TabletUp, { children: _jsx(Spacer, { x: "extraSmall" }) }), _jsx(Mobile, { children: _jsx(Spacer, { y: "extraSmall" }) }), _jsx("div", Object.assign({ className: styles.inputField }, { children: _jsx(DateRangerPicker, { dateRange: dateRange, onRangeChanged: onRangeChanged, label: _t('main.date-range') }) })), _jsx(TabletUp, { children: _jsx(Spacer, { x: "extraSmall" }) }), _jsx(Mobile, { children: _jsx(Spacer, { y: "extraSmall" }) }), _jsx("div", Object.assign({ className: styles.inputField }, { children: _jsx(ExportXLSXButton, { disabled: !hasRange || dataCount === 0 || token === null, defaultFilename: "transactions.xlsx", downloadLink: getExportUrl(currentWallet, start, end, token) }) }))] })))] }));
};
export default React.memo(StatementFilters);
