import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MobilePositionsList.module.scss';
import { actions, PositionSide, selectors, useAppDispatch, useAppSelector, useInstruments, } from 'wintrado-api';
import { formatDateTime, isSet } from '../../helpers';
import _t from 'counterpart';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import classNames from 'classnames';
import { formatCurrency } from 'utils/currency';
import Button from 'ui/components/Button/Button';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useHistory } from 'react-router-dom';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
const MobilePositionsList = ({ positions, onPositionSelected }) => {
    const { instruments, isLoading, isError } = useInstruments();
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const handleRowClick = (e, position) => {
        e.preventDefault();
        onPositionSelected === null || onPositionSelected === void 0 ? void 0 : onPositionSelected(position);
    };
    const handleModifyPosition = (e, positionId) => {
        e.preventDefault();
        if (!isSet(positionId)) {
            return;
        }
        dispatch(actions.setActivePositionId(positionId));
        const url = `/trading/positions/${positionId}`;
        if (history.location.pathname.includes('/trading/positions/')) {
            history.replace(url);
        }
        else {
            history.push(url);
        }
    };
    // TODO: Better loading / error state here
    if (isLoading || isError) {
        return _jsx("div", { className: styles.container });
    }
    if (positions.length === 0) {
        return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsx(EmptyPlaceholder, { title: _t('main.no_positions'), description: _t('main.no_positions_description') }) })));
    }
    const formatStopLoss = (position) => {
        if (isSet(position.stopLoss)) {
            return position.stopLoss;
        }
        if (isSet(position.trailingStopLossPct)) {
            return Intl.NumberFormat(_t.getLocale(), { style: 'percent', minimumFractionDigits: 0 }).format(position.trailingStopLossPct / 100);
        }
        return '—';
    };
    return (_jsx("div", Object.assign({ className: styles.container }, { children: positions
            .sort((a, b) => b.positionId - a.positionId)
            .map((position) => {
            if (!position.symbol) {
                return null;
            }
            const instrument = instruments[position.symbol];
            const latestQuote = latestQuotes[position.symbol];
            const currentPrice = position.side === PositionSide.BUY ? latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.bid : latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.ask;
            if (!instrument) {
                throw new Error(`Instrument ${position.symbol} not found`);
            }
            return (_jsxs("div", Object.assign({ className: styles.position, onClick: (e) => handleRowClick(e, position) }, { children: [_jsxs("div", Object.assign({ className: styles.basicInfoRow }, { children: [_jsx(InstrumentIconName, { className: styles.name, instrument: instrument }), _jsx("div", { children: isSet(position.openedAt) && formatDateTime(position.openedAt) })] })), _jsxs("div", Object.assign({ className: styles.detailsGrid }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: _t('main.type') })), _jsx("div", Object.assign({ className: styles.value }, { children: isSet(position.side) ? _t(`main.${position.side.toLowerCase()}`) : _jsx(LoadingDots, {}) })), _jsx("div", {}), _jsx("div", Object.assign({ className: styles.label }, { children: _t('main.stop_loss') })), _jsx("div", Object.assign({ className: classNames(styles.value, styles.stopLoss) }, { children: formatStopLoss(position) })), _jsx("div", Object.assign({ className: styles.label }, { children: _t('main.volume') })), _jsx("div", Object.assign({ className: classNames(styles.value, styles.size) }, { children: isSet(position.size) ? position.size : _jsx(LoadingDots, {}) })), _jsx("div", {}), _jsx("div", Object.assign({ className: styles.label }, { children: _t('main.current_price') })), _jsx("div", Object.assign({ className: classNames(styles.value, styles.currentPrice) }, { children: isSet(currentPrice) ? currentPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: styles.label }, { children: _t('main.open_price') })), _jsx("div", Object.assign({ className: classNames(styles.value, styles.openPrice) }, { children: isSet(position.openPrice) ? position.openPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", {}), _jsx("div", Object.assign({ className: styles.label }, { children: _t('main.profit') })), _jsx("div", Object.assign({ className: classNames(styles.value, styles.profit, {
                                    [styles.profitNegative]: isSet(position.profitLoss) && position.profitLoss < 0,
                                    [styles.profitPositive]: isSet(position.profitLoss) && position.profitLoss >= 0,
                                }) }, { children: isSet(position.profitLoss) ? formatCurrency(position.profitLoss, accountCurrency) : _jsx(LoadingDots, {}) }))] })), _jsx("div", Object.assign({ className: styles.closeWrapper }, { children: _jsx(Button, Object.assign({ variant: "secondary", condensed: "all", onClick: (e) => handleModifyPosition(e, position.positionId), flexible: true }, { children: _t('main.modify') })) }))] }), position.positionId));
        }) })));
};
export default React.memo(MobilePositionsList);
