import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import TradingSidebarLayout from 'components/TradingSidebarLayout/TradingSidebarLayout';
import InstrumentView from 'components/InstrumentView/InstrumentView';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import InstrumentsAndWatchlist from 'components/InstrumentsAndWatchlist/InstrumentsAndWatchlist';
const InstrumentSidebar = () => {
    const goBack = useGoBackTo('/trading/instruments');
    const { path } = useRouteMatch();
    return (_jsx(TradingSidebarLayout, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ path: `${path}/instruments/:symbol` }, { children: _jsx(InstrumentView, { onBackClick: goBack }) })), _jsx(Route, Object.assign({ exact: true, path: `${path}*` }, { children: _jsx(InstrumentsAndWatchlist, {}) }))] }) }));
};
export default React.memo(InstrumentSidebar);
