import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Main.module.scss';
import _t from 'counterpart';
import React, { useEffect, useState } from 'react';
import config from 'config';
import LoginGrid from './old/components/LoginGrid.tsx';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import ErrorMessage from './old/components/main/ErrorMessage';
import { isNotChrome, isStandalone, releaseVersion, requestOAuthLogin, setReferralParam } from './helpers';
import { OfflineNotice } from './old/components/global/OfflineNotice';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import PopupNotifications from './old/components/PopupNotification/PopupNotifications';
import PopupNotificationManager from './old/components/PopupNotification/PopupNotificationManager';
import DebugPopup from './old/components/main/DebugPopup';
import PopupAlert from './old/components/PopupAlert/PopupAlert';
import PopupAlertManager from './old/components/PopupAlert/PopupAlertManager';
import { MaintenanceScreen } from './old/components/global/MaintenanceScreen';
import { setMomentLocale } from 'utils/locales';
import { useQueryParams } from './hooks';
import MainLayout from './layout/MainLayout/MainLayout';
import { ToastProvider, ToastViewport } from '@radix-ui/react-toast';
import PriceAlertToasts from 'components/PriceAlertToasts/PriceAlertToasts';
import { toastDefaultDuration } from 'utils/contants';
import { setDefaultLocale } from 'react-datepicker';
import { ToastContextProvider } from 'components/Toast/ToastContext';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';
import { setSagaErrorLogger } from 'wintrado-api/src/utils/logger';
import PositionStatusToasts from 'components/PositionStatusToasts/PositionStatusToasts';
import PoorConnection from './old/components/PoorConnection/PoorConnection';
require('normalize.css/normalize.css');
require('sass/v3/app.scss');
require(`sass/themes/${__BRAND__}.scss`);
const Main = () => {
    const [locale, setLocale] = useState(_t.getLocale());
    const [hasError, setHasError] = useState(false);
    const queryParams = useQueryParams();
    const error = useAppSelector((state) => state.error);
    const refreshToken = useAppSelector((state) => state.user.refreshToken);
    const dontShowChromeInstallCTA = useAppSelector((state) => state.settings.dontShowChromeInstallCTA);
    const language = useAppSelector((state) => state.settings.language);
    const isOnline = useAppSelector((state) => state.connection.online);
    const apiError = useAppSelector((state) => state.connection.apiError);
    const initialConnectionFailed = useAppSelector((state) => state.connection.initialConnectionFailed);
    const apiErrorReason = useAppSelector((state) => state.connection.apiErrorReason);
    const maintenanceMode = useAppSelector((state) => state.maintenanceMode);
    const loginTimedOut = useAppSelector((state) => state.events.loginTimedOut);
    const dispatch = useAppDispatch();
    const logError = (error, errorInfo) => {
        if (process.env.NODE_ENV === 'development') {
            console.error(error);
            console.error(errorInfo);
        }
        else {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }
    };
    const handleErrorReceived = () => {
        let showError = true;
        if (error.type !== null && error.type.toLowerCase() === 'outside_of_trading_hours') {
            showError = false;
        }
        return (_jsx(ErrorMessage, { visible: error.type !== null && showError, title: error.type, message: error.message, extra: error.extra }));
    };
    const openChromeDownloadPage = (e) => {
        e.preventDefault();
        window.open('https://google.com/chrome');
    };
    const setDontShowChromeInstallCTA = (e) => {
        e.preventDefault();
        dispatch(actions.setDontShowChromeInstallCTA());
    };
    useEffect(() => {
        if (isNotChrome() && !dontShowChromeInstallCTA) {
            PopupNotificationManager.showNotification({
                id: 'chrome',
                primaryButtonTitleKey: 'main.download',
                onPrimaryButtonClicked: openChromeDownloadPage,
                secondaryButtonTitleKey: 'main.do_not_show_again',
                onSecondaryButtonClicked: setDontShowChromeInstallCTA,
                descriptionKey: 'main.chrome_installcta_description',
                descriptionExtra: { appName: config.appName },
            });
        }
        else {
            PopupNotificationManager.hideNotification('chrome');
        }
    });
    useEffect(() => {
        setSagaErrorLogger((error, info) => {
            logError(error, info);
            setHasError(true);
        });
    }, []);
    useEffect(() => {
        dispatch(actions.setReleaseVersion(releaseVersion()));
        if (queryParams.ref && !Array.isArray(queryParams.ref)) {
            setReferralParam(queryParams.ref);
        }
        if (queryParams.code && queryParams.state && !Array.isArray(queryParams.code)) {
            // TODO(pascal): We probably want to dispatch an error action here?
            if (window.sessionStorage.getItem('state') !== queryParams.state) {
                Sentry.captureException(new Error('state param mismatch during login'));
                return;
            }
            dispatch(actions.requestOAuthTokens(queryParams.code, window.location.origin));
        }
        if (queryParams.instrument && !Array.isArray(queryParams.instrument)) {
            dispatch(actions.setInstrumentToPreselect(queryParams.instrument));
        }
        if (queryParams.request_login) {
            requestOAuthLogin(language);
        }
        if (isStandalone()) {
            dispatch(actions.updateUser({ standalone: isStandalone() }));
        }
        return () => {
            PopupNotificationManager.unregisterContainer();
        };
        // We intentionally don't want to add deps here - we want this effect to effectively run as componentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const setLanguage = (lang) => {
            if (locale !== lang) {
                _t.setLocale(lang);
                setLocale(_t.getLocale());
                setMomentLocale(lang);
                setDefaultLocale(lang);
            }
        };
        setLanguage(language);
    }, [language, locale]);
    useEffect(() => {
        if (apiError && apiErrorReason) {
            logError(apiErrorReason, { type: 'ApiError' });
            if (initialConnectionFailed) {
                setHasError(true);
            }
        }
    }, [apiError, apiErrorReason, initialConnectionFailed]);
    if (hasError) {
        return _jsx(ErrorScreen, {});
    }
    if (maintenanceMode) {
        return _jsx(MaintenanceScreen, {});
    }
    if (loginTimedOut) {
        return _jsx(PoorConnection, {});
    }
    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorScreen, {}) }, { children: _jsx(ToastProvider, Object.assign({ duration: toastDefaultDuration }, { children: _jsxs(ToastContextProvider, { children: [_jsxs("div", Object.assign({ className: styles.container }, { children: [refreshToken ? _jsx(MainLayout, {}, locale) : _jsx(LoginGrid, {}, locale), handleErrorReceived(), _jsx(OfflineNotice, { visible: !isOnline }), _jsx(DebugPopup, {}), _jsx(PopupNotifications, { ref: (ref) => {
                                    if (ref) {
                                        PopupNotificationManager.registerContainer(ref);
                                    }
                                } }), _jsx(PopupAlert, { ref: (ref) => {
                                    if (ref) {
                                        PopupAlertManager.registerContainer(ref);
                                    }
                                } })] })), _jsx(ToastViewport, { className: styles.toastViewport }), _jsx(PriceAlertToasts, {}), _jsx(PositionStatusToasts, {})] }) })) })));
};
export default React.memo(Main);
