import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './StopLossInput.module.scss';
import _t from 'counterpart';
import Spacer from 'components/Spacer/Spacer';
import TextField from 'components/TextField/TextField';
import { TabletUp } from 'utils/responsiveHelpers';
import CrosshairToggleButton from 'components/CrosshairToggleButton/CrosshairToggleButton';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { FiCrosshair, FiPercent } from 'react-icons/fi';
import classNames from 'classnames';
import alertIcon from '../../old/icons/alert.svg?url';
const StopLossInput = (props) => {
    const { isTrailingStopLoss, toggleStopLossType, disablePriceSelectionIfActive, handleCrosshairClickedForField, registerStopLoss, registerTrailing, errorStopLoss, errorTrailing, stopLossPrice, activeFieldId, } = props;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.switchContainer }, { children: [_jsx("label", Object.assign({ className: styles.switchLabel }, { children: _t('order.stop-loss-label') })), _jsxs(SwitchPrimitive.Root, Object.assign({ id: "normal-trailing", className: styles.switch, checked: isTrailingStopLoss, onCheckedChange: toggleStopLossType }, { children: [_jsx(SwitchPrimitive.Thumb, { className: styles.switchThumb }), _jsxs("div", Object.assign({ className: styles.switchIcons }, { children: [_jsx(FiCrosshair, { size: 16, className: classNames(styles.switchIconLeft, {
                                            [styles.leftTransition]: isTrailingStopLoss,
                                        }) }), _jsx(FiPercent, { size: 16, className: classNames(styles.switchIconRight, {
                                            [styles.rightTransition]: isTrailingStopLoss,
                                        }) })] }))] }))] })), _jsx(Spacer, { y: "extraSmall" }), !isTrailingStopLoss && (_jsx(TextField, Object.assign({}, registerStopLoss, { label: _t('order.stoploss'), error: errorStopLoss, placeholder: stopLossPrice, monospace: true, copyPlaceholderToValueOnFocus: true, onFocus: disablePriceSelectionIfActive, inputMode: "decimal", iconEnd: _jsx(TabletUp, { children: _jsx(CrosshairToggleButton, { isActive: activeFieldId === 'stopLoss', fieldId: 'stopLoss', onCrosshairClickedForField: handleCrosshairClickedForField }) }) }))), isTrailingStopLoss && (_jsx(TextField, Object.assign({}, registerTrailing, { label: _t('order.trailing-stop-loss'), error: errorTrailing, placeholder: "87", monospace: true, onFocus: disablePriceSelectionIfActive, inputMode: "numeric", iconEnd: _jsx(FiPercent, { size: 30, className: styles.switchIconRight }) }))), _jsxs("div", Object.assign({ className: styles.stopLossDescription }, { children: [_jsx("img", { src: alertIcon, alt: "Warning" }), _jsx("span", { children: _t('order.stop-loss-description') })] }))] }));
};
export default StopLossInput;
