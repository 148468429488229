import { useAppSelector } from './storeHooks';
/**
 * React-Query-style hook to fetch instruments.
 * Use this in exchange for all manual fetching of instruments with custom selectors.
 *
 * The idea is that this will trigger fetching of instruments later on. Right now it does rely
 * on the instrument fetching being triggered by the `REQUEST_INSTRUMENTS` action being dispatched elsewhere.
 */
export function useInstruments() {
    const instruments = useAppSelector((state) => state.instruments);
    const instrumentsIsLoading = useAppSelector((state) => state.instrumentsIsLoading);
    if (instrumentsIsLoading) {
        return { isLoading: true, isError: false, instruments: null, error: null };
    }
    return {
        isLoading: false,
        isError: false,
        instruments: instruments,
        error: null,
    };
}
/**
 * React-Query-style hook to fetch a single instrument.
 * See `useInstruments` for details.
 */
export function useInstrument(symbol) {
    const instrument = useAppSelector((state) => { var _a; return (_a = state.instruments) === null || _a === void 0 ? void 0 : _a[symbol]; });
    const instrumentsIsLoading = useAppSelector((state) => state.instrumentsIsLoading);
    if (symbol === undefined || symbol === null) {
        return { isLoading: false, isError: false, instrument: null, error: null };
    }
    if (instrumentsIsLoading) {
        return { isLoading: true, isError: false, instrument: null, error: null };
    }
    return {
        isLoading: false,
        isError: false,
        instrument: instrument || null,
        error: null,
    };
}
