import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MobileTradingFooter.module.scss';
import Button from 'ui/components/Button/Button';
import { FiLayers } from 'react-icons/fi';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
import Badge from 'components/Badge/Badge';
import { selectors, useAppSelector } from 'wintrado-api';
const MobileTradingFooter = () => {
    const openPositionsCount = useAppSelector(selectors.openPositionsSelector).length;
    const pendingOrdersCount = useAppSelector(selectors.pendingOrdersSelector).length;
    const instrumentsCount = useAppSelector((state) => Object.entries(state.instruments).length);
    const isAttemptingReconnect = useAppSelector((state) => state.connection.isAttemptingReconnect);
    const positionsAndOrdersCount = openPositionsCount + pendingOrdersCount;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs(Button, Object.assign({ variant: 'secondary', to: '/trading/positions', alignStart: true, flexible: true }, { children: [_jsx(FiLayers, {}), _jsx(Spacer, { x: 'extraSmall' }), _t('main.orders'), _jsx(Spacer, { x: 'flex-expand' }), _jsx(Badge, { value: positionsAndOrdersCount })] })), _jsx(Button, Object.assign({ variant: 'primary', to: '/trading/place-order', disabled: instrumentsCount === 0 || isAttemptingReconnect, flexible: true }, { children: _t('main.place_order') }))] })));
};
export default React.memo(MobileTradingFooter);
