import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styles from "./IframeWrapper.module.scss";
import Loader from "components/Loader/Loader";
const IframeWrapper = ({ url }) => {
    const [isLoading, setIsLoading] = useState(true);
    const handleIframeLoaded = () => {
        setIsLoading(false);
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("iframe", { src: url, frameBorder: 0, onLoad: handleIframeLoaded, className: styles.iframe }), isLoading && _jsx(Loader, { opaqueBackground: true })] })));
};
export default React.memo(IframeWrapper);
