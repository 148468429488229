import React from 'react';
import { BackButton } from '../BackButton';

export const MobilePageHeader = ({ isShowingEmbeddedIframe = false }) => {
	return (
		<div className="mobile-page-header">
			<BackButton useCloseIcon={isShowingEmbeddedIframe} />
		</div>
	);
};
