import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _t from 'counterpart';
import pageNotFound from '../../assets/icons/page_not_found.svg?url';
import styles from './PageNotFoundScreen.module.scss';
import CustomIcon from 'components/CustomIcon/CustomIcon';
import Spacer from 'components/Spacer/Spacer';
import Button from 'ui/components/Button/Button';
import { useHistory } from 'react-router-dom';
const PageNotFoundScreen = () => {
    const history = useHistory();
    const navigateToTrading = () => {
        history.replace('/trading');
    };
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(CustomIcon, { src: pageNotFound, alt: "add-alert-icon", className: styles.icon }), _jsx(Spacer, { y: "medium" }), _jsx("h2", Object.assign({ className: styles.title }, { children: _t('main.page_not_found.title') })), _jsx(Spacer, { y: "medium" }), _jsx("p", { children: _t('main.page_not_found.message') }), _jsx(Spacer, { y: "large" }), _jsx(Button, Object.assign({ variant: 'primary', onClick: navigateToTrading, flexible: true }, { children: _t('main.page_not_found.action') }))] })) })));
};
export default PageNotFoundScreen;
