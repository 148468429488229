import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './InstrumentPerformanceStat.module.scss';
import _t from 'counterpart';
import PerformanceItem from 'components/InstrumentPerformanceStat/PerformanceItem';
import { useQuery } from '@tanstack/react-query';
import { actions, gatewayService, useAppDispatch } from 'wintrado-api';
import LoadingDots from 'components/LoadingDots/LoadingDots';
const InstrumentPerformanceStat = ({ instrumentSymbol }) => {
    const dispatch = useAppDispatch();
    const instrumentPerformanceQuery = useQuery({
        queryKey: ['instrument-performance', instrumentSymbol],
        queryFn: () => gatewayService.getInstrumentPerformance(instrumentSymbol),
        onError: (error) => {
            dispatch(actions.displayError('Failed fetching performance details', error.message));
        },
    });
    if (instrumentPerformanceQuery.isLoading) {
        return _jsx(LoadingDots, {});
    }
    if (instrumentPerformanceQuery.isError) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: _t('main.performance').toUpperCase() })), _jsxs("div", Object.assign({ className: styles.grid }, { children: [_jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.oneWeekPerformance, label: '1W' }), _jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.oneMonthPerformance, label: '1M' }), _jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.threeMonthsPerformance, label: '3M' }), _jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.sixMonthsPerformance, label: '6M' }), _jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.YTDPerformance, label: 'YTD' }), _jsx(PerformanceItem, { value: instrumentPerformanceQuery.data.yearPerformance, label: '1Y' })] }))] })));
};
export default React.memo(InstrumentPerformanceStat);
