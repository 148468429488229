import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _t from 'counterpart';
import styles from './Error.module.scss';
import Button from 'ui/components/Button/Button';
const Error = ({ message }) => {
    const onRefreshClicked = () => {
        window.location.reload();
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("h2", { children: _t('error.oops') }), _jsx("p", { children: message }), _jsx("p", { children: _t('error.refresh_to_continue') }), _jsx(Button, Object.assign({ variant: "primary", onClick: onRefreshClicked }, { children: _t('main.refresh') }))] })));
};
export default Error;
