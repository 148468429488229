import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './LoyaltyBenefits.module.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import _t from 'counterpart';
import { useQuery } from '@tanstack/react-query';
import { crmService } from 'wintrado-api';
import GenericErrorComponent from '../global/GenericErrorComponent';
import Loader from 'components/Loader/Loader';
const LoyaltyBenefits = () => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['trading-strategy'],
        queryFn: () => crmService.getLoyaltyLevels(),
    });
    if (isLoading) {
        return _jsx(Loader, { transparentBackground: true });
    }
    if (error) {
        return _jsx(GenericErrorComponent, { error: error.toString() });
    }
    return (_jsxs("div", { children: [_jsx("h5", Object.assign({ className: styles.title }, { children: _t.translate('loyalty.benefits_title') })), _jsx("p", Object.assign({ className: styles.subtitle }, { children: _t.translate('loyalty.benefits_subtitle') })), _jsxs(Table, Object.assign({ className: styles.table }, { children: [_jsx(Thead, { children: _jsxs(Tr, { children: [_jsx(Th, { children: _t.translate('loyalty.level') }), _jsx(Th, { children: _t.translate('loyalty.coins_required') }), _jsx(Th, { children: _t.translate('loyalty.multiplier') }), _jsx(Th, { children: _t.translate('loyalty.expiration') }), _jsx(Th, { children: _t.translate('loyalty.maintaining_coins_required') }), _jsx(Th, { children: _t.translate('loyalty.benefits') })] }) }), _jsx(Tbody, { children: data.map((loyaltyLevel) => (_jsxs(Tr, { children: [_jsxs(Td, { children: [_jsx("img", { src: require(`../../icons/${loyaltyLevel.name}-crown.svg?url`), alt: loyaltyLevel.name, className: styles.crownIcon }), _t.translate(`loyalty.levels.${loyaltyLevel.name}`)] }), _jsx(Td, { children: loyaltyLevel.awardThreshold }), _jsx(Td, { children: `x${loyaltyLevel.multiplier}` }), _jsx(Td, { children: loyaltyLevel.level > 1 ? _t.translate('loyalty.x_days', { days: 30 }) : '—' }), _jsx(Td, { children: loyaltyLevel.retentionThreshold }), _jsx(Td, { children: _t.translate(`loyalty.${loyaltyLevel.realMoney ? 'x_coins_to_y_real_money' : 'x_coins_to_y_bonus'}`, {
                                        coins: 300,
                                        amount: '$5',
                                    }) })] }, loyaltyLevel.id))) })] }))] }));
};
export default LoyaltyBenefits;
