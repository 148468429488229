import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './InstrumentStat.module.scss';
import InstrumentIcon from 'components/InstrumentIcon/InstrumentIcon';
import Spacer from 'components/Spacer/Spacer';
import { isSet } from '../../helpers';
import _t from 'counterpart';
import classNames from 'classnames';
import { selectors, useAppSelector } from 'wintrado-api';
const InstrumentStat = ({ instrument, positionSide }) => {
    const lastInstrumentQuotes = useAppSelector(selectors.latestQuoteForSymbolSelector(instrument.symbol));
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(InstrumentIcon, { imgUrl: instrument.iconUrl, symbol: instrument.symbol }), _jsx(Spacer, { x: "extraSmall" }), _jsxs("div", Object.assign({ className: styles.instrumentInfo }, { children: [_jsx("span", Object.assign({ className: styles.instrumentName }, { children: instrument.symbol })), _jsxs("div", Object.assign({ className: styles.priceContainer }, { children: [_jsx("span", Object.assign({ className: styles.priceSide }, { children: `${positionSide === 'buy' ? _t('main.ask') : _t('main.bid')}:` })), "\u00A0", isSet(lastInstrumentQuotes) && (_jsx("span", Object.assign({ className: classNames(styles.priceValue, {
                                    [styles.priceValueAsk]: positionSide === 'buy',
                                    [styles.priceValueBid]: positionSide === 'sell',
                                }) }, { children: (positionSide === 'buy' ? lastInstrumentQuotes.ask : lastInstrumentQuotes.bid).toFixed(instrument.decimals) })))] }))] }))] })));
};
export default React.memo(InstrumentStat);
