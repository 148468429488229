import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import styles from './AnalysisChart.module.scss';
import { widget, } from '../../charting_library';
import { analysisChartDatafeed } from 'utils/analysisChartDataFeed';
import { setChartResolution } from 'wintrado-api/src/actions';
import { actions, PositionSide, useAppDispatch, useAppSelector, useInstruments } from 'wintrado-api';
import _t from 'counterpart';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isSet } from '../../helpers';
import { useForwardRef } from '../../hooks';
import { saveLoadAdapter } from 'utils/analysisChartSaveLoadAdapter';
import { trading } from '../../colors';
import NoInstrumentsFound from 'components/NoInstrumentsFound/NoInstrumentsFound';
import LostConnectionDialog from '../../old/components/LostConnectionDialog/LostConnectionDialog';
const AnalysisChart = forwardRef(({ showPlaceOrderButton = false, showSymbolSelectButton = false, hideHeaderFullscreenbutton = false }, ref) => {
    var _a, _b, _c, _d;
    const chartContainerRef = useForwardRef(ref);
    const initialActiveInstrumentSymbol = useAppSelector((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; }, () => true);
    const initialActiveResolution = useAppSelector((state) => state.settings.chartResolution, () => true);
    const { instruments, isLoading: instrumentsIsLoading } = useInstruments();
    const instrumentsCount = instruments !== null ? Object.keys(instruments).length : 0;
    const activeInstrumentSymbol = useAppSelector((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    const activeResolution = useAppSelector((state) => state.settings.chartResolution);
    const crosshairPriceSelectionEnabled = useAppSelector((state) => state.proTradingScreen.crosshairPriceSelection.enabled);
    const positionRouteMatch = useRouteMatch('/trading/positions/:positionId');
    const activePositionId = positionRouteMatch === null || positionRouteMatch === void 0 ? void 0 : positionRouteMatch.params.positionId;
    const activePositionStopLoss = (_a = useAppSelector((state) => state.positions[activePositionId])) === null || _a === void 0 ? void 0 : _a.stopLoss;
    const activePositionTakeProfit = (_b = useAppSelector((state) => state.positions[activePositionId])) === null || _b === void 0 ? void 0 : _b.takeProfit;
    const activePositionOpenPrice = (_c = useAppSelector((state) => state.positions[activePositionId])) === null || _c === void 0 ? void 0 : _c.openPrice;
    const activePositionSide = (_d = useAppSelector((state) => state.positions[activePositionId])) === null || _d === void 0 ? void 0 : _d.side;
    const isAttemptingReconnect = useAppSelector((state) => state.connection.isAttemptingReconnect);
    const dispatch = useAppDispatch();
    const tvWidgetRef = useRef(null);
    const placeOrderButtonRef = useRef(null);
    const history = useHistory();
    useEffect(() => {
        // During the first render, the initialActiveInstrumentSymbol is not set yet because it depends on instruments being fetched first.
        // We therefore stop initialization here in that case and only initialize the chart once it's set.
        // That means that tvWidgetRef.current will be null until the chart is initialized which needs to be handled in all other useEffects.
        // A better solution may be to just pass the initialActiveInstrumentSymbol and initialActiveResolution as props once the instruments
        // have been fetched and delay rendering the entire component until then.
        if (!initialActiveInstrumentSymbol) {
            return;
        }
        const disabledFeatures = ['header_screenshot', 'header_compare'];
        if (!showSymbolSelectButton) {
            disabledFeatures.push('header_symbol_search');
        }
        if (hideHeaderFullscreenbutton) {
            disabledFeatures.push('header_fullscreen_button');
        }
        const widgetOptions = {
            symbol: initialActiveInstrumentSymbol,
            interval: initialActiveResolution,
            library_path: '/charting_library/',
            fullscreen: false,
            autosize: true,
            theme: 'dark',
            datafeed: analysisChartDatafeed,
            container: chartContainerRef.current,
            locale: 'en',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            disabled_features: disabledFeatures,
            custom_css_url: '/custom_chart.css',
            overrides: {
                'paneProperties.backgroundType': 'solid',
                'paneProperties.background': '#21232B',
            },
            save_load_adapter: saveLoadAdapter,
            auto_save_delay: 2,
            load_last_chart: true,
        };
        const handleIntervalChanged = (interval) => {
            dispatch(setChartResolution(interval));
        };
        const handleSymbolChanged = () => {
            var _a;
            const symbol = (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().symbol();
            if (symbol) {
                dispatch(actions.setActiveInstrument(symbol));
            }
        };
        const handleAutoSaveNeeded = () => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.saveChartToServer(undefined, undefined, {
                defaultChartName: 'My Chart',
            });
        };
        tvWidgetRef.current = new widget(widgetOptions);
        tvWidgetRef.current.onChartReady(() => {
            var _a, _b, _c;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().onIntervalChanged().subscribe(null, handleIntervalChanged);
            (_b = tvWidgetRef.current) === null || _b === void 0 ? void 0 : _b.activeChart().onSymbolChanged().subscribe(null, handleSymbolChanged);
            (_c = tvWidgetRef.current) === null || _c === void 0 ? void 0 : _c.subscribe('onAutoSaveNeeded', handleAutoSaveNeeded);
        });
        return () => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.remove();
        };
    }, [initialActiveInstrumentSymbol, initialActiveResolution, chartContainerRef, dispatch, showSymbolSelectButton]);
    useEffect(() => {
        if (tvWidgetRef.current === null) {
            return;
        }
        const handleShowPlaceOrder = () => {
            history.push('/trading/place-order');
        };
        if (showPlaceOrderButton) {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                // this workaround is required due to the fact that TradingView does not handle removing buttons properly
                // thus leaving separators in the header if we try to remove the button and replace it with a new one
                if (placeOrderButtonRef.current) {
                    placeOrderButtonRef.current.addEventListener('click', handleShowPlaceOrder);
                    if (isAttemptingReconnect) {
                        placeOrderButtonRef.current.classList.add('button-disabled');
                        placeOrderButtonRef.current.removeEventListener('click', handleShowPlaceOrder);
                    }
                    else {
                        placeOrderButtonRef.current.classList.remove('button-disabled');
                        placeOrderButtonRef.current.classList.add('button');
                        placeOrderButtonRef.current.addEventListener('click', handleShowPlaceOrder);
                    }
                }
                else {
                    (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.headerReady().then(() => {
                        var _a;
                        placeOrderButtonRef.current = (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.createButton({
                            align: 'right',
                            useTradingViewStyle: false,
                        });
                        if (isSet(placeOrderButtonRef.current)) {
                            if (instrumentsCount === 0) {
                                placeOrderButtonRef.current.setAttribute('title', _t('main.click_to_place_order') + `(${_t('main.disabled')})`);
                                placeOrderButtonRef.current.classList.add('button-disabled');
                                placeOrderButtonRef.current.innerHTML = `+ ${_t('main.order')}`;
                            }
                            else {
                                placeOrderButtonRef.current.setAttribute('title', _t('main.click_to_place_order') + '(Ctrl+O)');
                                placeOrderButtonRef.current.classList.add('button');
                                placeOrderButtonRef.current.addEventListener('click', handleShowPlaceOrder);
                                placeOrderButtonRef.current.innerHTML = `+ ${_t('main.order')}`;
                            }
                        }
                    });
                }
            });
        }
        return () => {
            var _a;
            (_a = placeOrderButtonRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', handleShowPlaceOrder);
        };
    }, [dispatch, history, showPlaceOrderButton, isAttemptingReconnect, instrumentsCount]);
    useEffect(() => {
        if (tvWidgetRef.current === null) {
            return;
        }
        const handleGoToInstruments = (e) => {
            e.stopPropagation();
            history.push('/trading/instruments');
        };
        if (showSymbolSelectButton) {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.headerReady().then(() => {
                    var _a;
                    // tradinview charts do not support overriding symbol search button, so this is a workaround to enable us to do that
                    const iframe = chartContainerRef.current.querySelectorAll(':scope > iframe')[0];
                    const originalButton = (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.document.getElementById('header-toolbar-symbol-search');
                    originalButton === null || originalButton === void 0 ? void 0 : originalButton.addEventListener('click', handleGoToInstruments);
                });
            });
        }
    }, [chartContainerRef, dispatch, history, showSymbolSelectButton]);
    useEffect(() => {
        if (tvWidgetRef.current === null) {
            return;
        }
        const handleCrosshairMoved = ({ time, price }) => {
            dispatch(actions.setLiveCrosshairPrice(price));
        };
        if (crosshairPriceSelectionEnabled) {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().crossHairMoved().subscribe(null, handleCrosshairMoved);
            });
        }
        else {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().crossHairMoved().unsubscribeAll(null);
            });
        }
        // cleaning handled by tvWidgetRef.current.remove() call in useEffect above
    }, [crosshairPriceSelectionEnabled, dispatch]);
    useEffect(() => {
        var _a;
        if (tvWidgetRef.current === null) {
            return;
        }
        const handleMouseUp = () => {
            dispatch(actions.selectCurrentCrosshairPrice());
        };
        const iframe = chartContainerRef.current.querySelectorAll(':scope > iframe')[0];
        if (crosshairPriceSelectionEnabled) {
            (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            var _a;
            (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseup', handleMouseUp);
        };
    }, [chartContainerRef, crosshairPriceSelectionEnabled, dispatch]);
    useEffect(() => {
        if (tvWidgetRef.current === null) {
            return;
        }
        tvWidgetRef.current.onChartReady(() => {
            var _a;
            if (activeInstrumentSymbol && activeResolution) {
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.setSymbol(activeInstrumentSymbol, activeResolution, () => null);
            }
        });
    }, [activeInstrumentSymbol, activeResolution]);
    useEffect(() => {
        const MAX_RETRIES = 5;
        const RETRY_DELAY = 200;
        let timer;
        let stopLossOrderLine;
        let takeProfitOrderLine;
        let openPriceOrderLine;
        const setupOrderLines = (retryCount = 0) => {
            if (tvWidgetRef.current === null) {
                return;
            }
            tvWidgetRef.current.onChartReady(() => {
                var _a, _b, _c;
                try {
                    if (activePositionStopLoss) {
                        stopLossOrderLine = (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().createOrderLine().setEditable(false).setCancellable(false).setLineColor(trading.negative).setQuantityBorderColor(trading.negative).setQuantityBackgroundColor('rgba(0, 0, 0, 0.0)').setText('').setQuantity('SL').setPrice(activePositionStopLoss);
                    }
                    if (activePositionTakeProfit) {
                        takeProfitOrderLine = (_b = tvWidgetRef.current) === null || _b === void 0 ? void 0 : _b.activeChart().createOrderLine().setEditable(false).setCancellable(false).setLineColor(trading.positive).setQuantityBorderColor(trading.positive).setQuantityBackgroundColor('rgba(0, 0, 0, 0.0)').setQuantity('TP').setText('').setPrice(activePositionTakeProfit);
                    }
                    if (activePositionOpenPrice) {
                        openPriceOrderLine = (_c = tvWidgetRef.current) === null || _c === void 0 ? void 0 : _c.activeChart().createOrderLine().setEditable(false).setCancellable(false).setLineColor(activePositionSide === PositionSide.BUY ? trading.buy : trading.sell).setQuantityBorderColor(activePositionSide === PositionSide.BUY ? trading.buy : trading.sell).setQuantityBackgroundColor('rgba(0, 0, 0, 0.0)').setQuantity('OP').setText('').setPrice(activePositionOpenPrice);
                    }
                }
                catch (e) {
                    // timeout and retry is required to avoid race-condition with TradingView symbol update
                    // see: https://github.com/tradingview/charting_library/issues/6649
                    if (e.message === 'Value is null') {
                        if (retryCount < MAX_RETRIES) {
                            timer = setTimeout(() => setupOrderLines(retryCount + 1), RETRY_DELAY);
                        }
                        else {
                            Sentry.captureException(`Failed to set order lines on TradingView chart: 'Value is null' after ${MAX_RETRIES} retries`);
                        }
                    }
                    else {
                        throw e;
                    }
                }
            });
        };
        setupOrderLines();
        return () => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.onChartReady(() => {
                if (timer) {
                    clearTimeout(timer);
                }
                stopLossOrderLine === null || stopLossOrderLine === void 0 ? void 0 : stopLossOrderLine.remove();
                takeProfitOrderLine === null || takeProfitOrderLine === void 0 ? void 0 : takeProfitOrderLine.remove();
                openPriceOrderLine === null || openPriceOrderLine === void 0 ? void 0 : openPriceOrderLine.remove();
            });
        };
    }, [activePositionStopLoss, activePositionTakeProfit, activePositionOpenPrice, activePositionSide]);
    return (_jsxs(_Fragment, { children: [instrumentsCount === 0 && !instrumentsIsLoading && _jsx(NoInstrumentsFound, {}), isAttemptingReconnect && _jsx(LostConnectionDialog, {}), _jsx("div", { ref: chartContainerRef, className: styles.container })] }));
});
AnalysisChart.displayName = 'Test';
export default React.memo(AnalysisChart);
