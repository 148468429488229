import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './UserAvatar.module.scss';
import * as Avatar from '@radix-ui/react-avatar';
import { getInitials, isSet } from '../../helpers';
import { useAppSelector } from 'wintrado-api';
const UserAvatar = () => {
    const avatarUrl = useAppSelector((state) => state.user.avatarUrl);
    const username = useAppSelector((state) => state.user.name);
    const userInitials = useMemo(() => {
        if (!username) {
            return null;
        }
        return getInitials(username);
    }, [username]);
    return (_jsxs(Avatar.Root, Object.assign({ className: styles.avatarRoot }, { children: [isSet(avatarUrl) && _jsx(Avatar.Image, { className: styles.avatarImage, src: avatarUrl, alt: username !== null && username !== void 0 ? username : '' }), _jsx(Avatar.Fallback, Object.assign({ className: styles.avatarFallback, delayMs: 600 }, { children: userInitials }))] })));
};
export default React.memo(UserAvatar);
