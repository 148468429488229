import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import _t from 'counterpart';
import InstrumentsList from 'components/InstrumentsList/InstrumentsList';
import { useQuery } from '@tanstack/react-query';
import { crmService, useAppSelector } from 'wintrado-api';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
import Button from 'ui/components/Button/Button';
const MobileWatchlistScreen = () => {
    const goBack = useGoBackTo('/trading');
    const instruments = useAppSelector((state) => state.instruments);
    const watchlistSymbols = useQuery({
        queryKey: ['watchlist'],
        queryFn: () => crmService.getWatchlist(),
    });
    const watchlistInstruments = useMemo(() => {
        if (!isSet(watchlistSymbols.data) || watchlistSymbols.data.length < 1) {
            return [];
        }
        return watchlistSymbols.data.map((w) => instruments[w.symbol]).filter(isSet);
    }, [instruments, watchlistSymbols.data]);
    return (_jsx(MobileScreenLayout, Object.assign({ title: _t('main.watchlist'), onBackClick: goBack }, { children: _jsx(InstrumentsList, { instruments: watchlistInstruments, emptyPlaceholder: _jsx(Alert, { title: _t('main.no-instruments-title'), description: _t('main.empty_watchlist_description'), variant: 'warning', fillParent: true, actions: _jsx(Button, Object.assign({ variant: 'primary', to: '/trading/instruments', flexible: true }, { children: _t('main.instruments_list') })) }) }) })));
};
export default React.memo(MobileWatchlistScreen);
