import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { usePositionsWithChangedStatus, useToastContext } from '../../hooks';
import _t from 'counterpart';
import ClosedPositionToastDescription from 'components/ClosedPositionToastDescription/ClosedPositionToastDescription';
import { useAppSelector } from 'wintrado-api';
const PositionStatusToasts = () => {
    const instruments = useAppSelector((state) => state.instruments);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const addToast = useToastContext();
    const { newPositions, closedPositions } = usePositionsWithChangedStatus();
    useEffect(() => {
        if (!newPositions || !closedPositions)
            return;
        closedPositions.forEach((position) => {
            if (!position || !position.closePrice || !position.positionId || !position.symbol || !position.profitLoss) {
                return;
            }
            addToast({
                variant: 'success',
                title: _t('toast.position_closed_title', { positionId: `#${position.positionId}` }),
                description: (_jsx(ClosedPositionToastDescription, { closePrice: position.closePrice, instrumentDecimals: instruments[position.symbol].decimals, profitLoss: position.profitLoss, accountCurrency: accountCurrency })),
            });
        });
        newPositions.forEach((position) => {
            if (!position || !position.openPrice || !position.positionId || !position.symbol) {
                return;
            }
            addToast({
                variant: 'success',
                title: _t('toast.position_opened_title', { positionId: `#${position.positionId}` }),
                description: _t('toast.position_opened_description', {
                    openPrice: position.openPrice.toFixed(instruments[position.symbol].decimals),
                }),
            });
        });
    }, [newPositions, closedPositions]);
    return null;
};
export default React.memo(PositionStatusToasts);
