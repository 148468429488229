import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import _t from 'counterpart';
import { selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import { setLowMarginModalShown } from 'wintrado-api/src/actions';
import styles from './ModalLowMargin.module.scss';
import Button from 'ui/components/Button/Button';
const ModalLowMargin = ({ onRequestClose }) => {
    const marginCall = useAppSelector(selectors.marginCallSelector);
    const lowMarginModalShown = useAppSelector((state) => state.events.lowMarginModalShown);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        const showModal = () => {
            setIsOpen(true);
            dispatch(setLowMarginModalShown());
        };
        if (marginCall && !lowMarginModalShown) {
            showModal();
        }
    }, [marginCall, lowMarginModalShown, dispatch]);
    return (_jsx(Modal, Object.assign({ title: _t('modal.margin_low.title'), description: _t('modal.margin_low.description'), onRequestClose: onRequestClose !== null && onRequestClose !== void 0 ? onRequestClose : handleCloseModal, isOpen: isOpen, className: styles.container }, { children: _jsx("div", Object.assign({ className: styles.holder }, { children: _jsx(Button, Object.assign({ variant: "primary", className: styles.button, onClick: handleCloseModal, flexible: true }, { children: _t.translate('main.i_got_it') })) })) })));
};
export default React.memo(ModalLowMargin);
