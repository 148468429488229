var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styles from './ModalInstrumentPriceAlert.module.scss';
import Modal from 'components/Modal/Modal';
import _t from 'counterpart';
import { selectors, useAppSelector } from 'wintrado-api';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import InstrumentAskBidSpread from 'components/InstrumentAskBidSpread/InstrumentAskBidSpread';
import ToggleGroup from 'components/ToggleGroup/ToggleGroup';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { capitalize, isSet } from '../../helpers';
import TextField from 'components/TextField/TextField';
import Spacer from 'components/Spacer/Spacer';
import FormLabel from 'components/FormLabel/FormLabel';
import { usePrevious } from '../../hooks/usePrevious';
import Button from 'ui/components/Button/Button';
import Alert from 'components/Alert/Alert';
import classNames from 'classnames';
import Collapsible from 'components/Collapsible/Collapsible';
import CollapsibleTrigger from 'components/Collapsible/CollapsibleTrigger';
import CollapsibleContent from 'components/Collapsible/CollapsibleContent';
import DatepickerField from 'components/Datepicker/Datepicker';
import { add } from 'date-fns';
const ModalInstrumentPriceAlert = ({ instrument, onRequestClose, createPriceAlertMutation }) => {
    var _a;
    const latestQuoteForInstrument = useAppSelector(selectors.latestQuotesSelector)[instrument.symbol];
    const previousLatestQuote = usePrevious(latestQuoteForInstrument);
    const [until, setUntil] = useState('forever');
    const createPriceAlertSchema = z.object({
        symbol: z.string().min(1),
        side: z.enum(['ask', 'bid']),
        condition: z.enum(['above', 'below']),
        price: z.any().pipe(z.coerce.number().positive()),
        expirationDate: z
            .date({
            errorMap: (error, ctx) => {
                if (error.code === 'too_small') {
                    return { message: _t('error.date_must_be_after_today') };
                }
                return { message: ctx.defaultError };
            },
        })
            .min(new Date())
            .optional(),
    });
    const { register, handleSubmit, control, formState: { errors }, setValue, } = useForm({
        resolver: zodResolver(createPriceAlertSchema),
        defaultValues: {
            symbol: instrument.symbol,
            side: 'ask',
            condition: 'above',
            // validator will take care to convert these to undefined, we shouldn't pass undefined for inputs as we're using controlled components
            price: '',
            expirationDate: undefined,
        },
    });
    // clears value of date input when until is changed
    useEffect(() => {
        if (until !== 'date') {
            setValue('expirationDate', undefined);
        }
        else {
            setValue('expirationDate', add(new Date(), { days: 1 }));
        }
    }, [until, setValue]);
    // sets the value of price input only on first quote received for instrument
    useEffect(() => {
        if (previousLatestQuote === undefined && latestQuoteForInstrument !== undefined) {
            setValue('price', latestQuoteForInstrument.value.toFixed(instrument.decimals));
        }
    }, [instrument, latestQuoteForInstrument, previousLatestQuote, setValue]);
    const onSubmit = (data) => {
        const { side, condition } = data, rest = __rest(data, ["side", "condition"]);
        const combinedCondition = `${side}_${condition}`.toUpperCase();
        createPriceAlertMutation.mutate(Object.assign({ condition: combinedCondition }, rest));
    };
    return (_jsxs(Modal, Object.assign({ title: _t('main.configure_alert'), description: _t('main.configure_alert_description'), onRequestClose: onRequestClose, isOpen: instrument !== null, hideTitleDescription: createPriceAlertMutation.isSuccess }, { children: [createPriceAlertMutation.isSuccess && (_jsx(Alert, { fillParent: true, title: _t('main.alert_set'), description: _t('main.alert_set_description'), variant: 'success', autoClose: true, onClose: onRequestClose, actions: _jsx(Button, Object.assign({ onClick: onRequestClose, variant: 'primary', flexible: true }, { children: _t('main.continue') })) })), isSet(latestQuoteForInstrument) && (_jsxs("div", Object.assign({ className: classNames(styles.container, {
                    [styles.containerHidden]: createPriceAlertMutation.isSuccess,
                }) }, { children: [_jsxs("div", Object.assign({ className: styles.row }, { children: [_jsx(InstrumentIconName, { instrument: instrument }), _jsx(InstrumentAskBidSpread, { ask: latestQuoteForInstrument.ask, bid: latestQuoteForInstrument.bid, spread: latestQuoteForInstrument.spread, decimals: instrument.decimals })] })), _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({}, register('symbol'), { type: "hidden", value: instrument.symbol })), _jsx(Controller, { name: 'side', control: control, render: ({ field: { onChange, value, ref } }) => (_jsx(ToggleGroup, { ref: ref, label: _t('main.notify_when'), className: styles.priceSideToggle, value: value, onValueChange: onChange, items: [
                                        { label: capitalize(_t('main.ask')), value: 'ask' },
                                        { label: capitalize(_t('main.bid')), value: 'bid' },
                                    ], variant: 'primary', flexible: true })) }), _jsx(Spacer, { y: "small" }), _jsx(Controller, { name: 'condition', control: control, render: ({ field: { onChange, value, ref } }) => (_jsx(ToggleGroup, { ref: ref, label: _t('main.is'), className: styles.priceSideToggle, value: value, onValueChange: onChange, items: [
                                        { label: capitalize(_t('main.higher')), value: 'above' },
                                        { label: capitalize(_t('main.lower')), value: 'below' },
                                    ], variant: 'primary', flexible: true })) }), _jsx(Spacer, { y: "small" }), _jsx(FormLabel, { children: _t('main.or_equal_to') }), _jsx(TextField, Object.assign({}, register('price'), { label: _t('main.price'), error: (_a = errors.price) === null || _a === void 0 ? void 0 : _a.message })), _jsx(Spacer, { y: "small" }), _jsxs(Collapsible, Object.assign({ open: until === 'date' }, { children: [_jsx(CollapsibleTrigger, Object.assign({ asChild: true }, { children: _jsx(ToggleGroup, { label: _t('main.until'), className: styles.priceSideToggle, value: until, onValueChange: setUntil, items: [
                                                { label: capitalize(_t('main.forever')), value: 'forever' },
                                                { label: capitalize(_t('main.date')), value: 'date' },
                                            ], variant: 'primary', flexible: true }) })), _jsxs(CollapsibleContent, { children: [_jsx(Spacer, { y: "small" }), _jsx(Controller, { name: 'expirationDate', control: control, render: ({ field: { onChange, value, ref } }) => {
                                                    var _a;
                                                    return (_jsx(DatepickerField, { ref: ref, label: _t('main.expiration_date'), value: value, onChange: onChange, error: (_a = errors.expirationDate) === null || _a === void 0 ? void 0 : _a.message }));
                                                } })] })] })), _jsxs("div", Object.assign({ className: styles.buttonsWrapper }, { children: [_jsx(Button, Object.assign({ variant: 'secondary', onClick: onRequestClose, flexible: true }, { children: _t('main.cancel') })), _jsx(Button, Object.assign({ type: 'submit', variant: "primary", flexible: true, loading: createPriceAlertMutation.isLoading }, { children: _t('main.save') }))] }))] }))] })))] })));
};
export default React.memo(ModalInstrumentPriceAlert);
