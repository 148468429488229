import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import styles from './InstrumentsAndWatchlist.module.scss';
import _t from 'counterpart';
import InstrumentsList from 'components/InstrumentsList/InstrumentsList';
import Tabs from 'components/Tabs/Tabs';
import { crmService, useAppSelector } from 'wintrado-api';
import { useQuery } from '@tanstack/react-query';
import { isSet } from '../../helpers';
const InstrumentsAndWatchlist = () => {
    const instruments = useAppSelector((state) => state.instruments);
    const instrumentsArray = useMemo(() => Object.values(instruments), [instruments]);
    const [activeTab, setActiveTab] = useState('instruments');
    const watchlistSymbols = useQuery({
        queryKey: ['watchlist'],
        queryFn: () => crmService.getWatchlist(),
    });
    const watchlistInstruments = useMemo(() => {
        if (!isSet(watchlistSymbols.data) || watchlistSymbols.data.length < 1) {
            return [];
        }
        return watchlistSymbols.data.map((w) => instruments[w.symbol]).filter(isSet);
    }, [instruments, watchlistSymbols.data]);
    return (_jsx(Tabs, { value: activeTab, onValueChange: setActiveTab, listClassName: styles.header, content: [
            {
                value: 'instruments',
                label: _t('main.instruments'),
                content: _jsx(InstrumentsList, { instruments: instrumentsArray, showSearch: true }),
            },
            {
                value: 'watchlist',
                label: _t('main.watchlist'),
                content: _jsx(InstrumentsList, { instruments: watchlistInstruments }),
            },
        ] }));
};
export default React.memo(InstrumentsAndWatchlist);
