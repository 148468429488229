import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './PendingOrderItem.module.scss';
import _t from 'counterpart';
import { formatDateTime, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { OrderDurationTitle } from 'wintrado-api/src/reducers/orders/types';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
const PendingOrdersItem = ({ order, isNew, instrument, currentPrice }) => {
    const history = useHistory();
    const timeoutRef = useRef(null);
    const [isNewLocked, setIsNewLocked] = useState(false);
    const handleRowClick = useCallback((e, order) => {
        const url = `/trading/orders/${order.id}`;
        if (history.location.pathname.includes('/trading/orders/')) {
            history.replace(url);
        }
        else {
            history.push(url);
        }
    }, [history]);
    const editingOrderId = useMemo(() => {
        if (!history.location.pathname.includes('/trading/orders/')) {
            return null;
        }
        return Number(history.location.pathname.split('/').pop());
    }, [history.location.pathname]);
    useEffect(() => {
        if (isNew) {
            setIsNewLocked(true);
        }
    }, [isNew]);
    useEffect(() => {
        if (isNewLocked) {
            timeoutRef.current = setTimeout(() => {
                setIsNewLocked(false);
            }, 4000);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isNewLocked]);
    return (_jsxs("div", Object.assign({ onClick: (e) => handleRowClick(e, order), className: classNames(styles.pendingOrder, {
            [styles.pendingOrderActive]: editingOrderId === order.id,
            [styles.pendingOrderNewOrder]: isNewLocked,
        }) }, { children: [_jsxs("div", { children: ["#", order.id] }), _jsx(InstrumentIconName, { instrument: instrument }), _jsx("div", { children: formatDateTime(order.placedAt) }), _jsx("div", { children: _t(`main.${order.side.toLowerCase()}`) }), _jsx("div", Object.assign({ className: styles.size }, { children: order.size })), _jsx("div", Object.assign({ className: styles.stopPrice }, { children: isSet(order.stopPrice) ? order.stopPrice.toFixed(instrument.decimals) : '—' })), _jsx("div", Object.assign({ className: styles.limitPrice }, { children: isSet(order.limitPrice) ? order.limitPrice.toFixed(instrument.decimals) : '—' })), _jsx("div", Object.assign({ className: styles.currentPrice }, { children: isSet(currentPrice) ? currentPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", { children: isSet(order.orderDuration) ? (_jsx("div", Object.assign({ title: OrderDurationTitle[order.orderDuration] }, { children: order.orderDuration }))) : ('—') })] })));
};
export default PendingOrdersItem;
